$sides: (
  'top': 't',
  'right': 'r',
  'bottom': 'b',
  'left': 'l',
);

@each $side, $letter in $sides {
  @for $px from 1 through 10 {
    .m#{$letter}-#{$px * 5} {
      margin-#{$side}: #{$px * 5}px !important;
    }

    .m-#{$px * 5} {
      margin: #{$px * 5}px !important;
    }
  }
}
