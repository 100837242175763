@use "macro-css/index" as *;
@use "./config" as *;
@use "./pulse" as *;

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-success {
  color: #0c4128;
}

.alert-message {
  position: fixed;
  top: -5%;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-animation: showOut 2.5s ease-out;
  animation: showOut 2.5s ease-out;
  z-index: 1003;
}

.thunder-holder {
  position: relative;
}

.thunder {
  position: absolute;
  top: -54%;
  left: 38%;
  width: 200px;
  z-index: -100;
}

@-webkit-keyframes showOut {  
  30%,
  100% {
    opacity: 1;
  }
  30% {
    -webkit-transform: translate(-50%, 115%);
    transform: translate(-50%, 115%);
  }
  100% {
    -webkit-transform: translate(-50%, 115%);
    transform: translate(-50%, 115%);
  }
}

@keyframes showOut {
  30%,
  100% {
    opacity: 1;
  }
  30% {
    -webkit-transform: translate(-50%, 115%);
    transform: translate(-50%, 115%);
  }
  100% {
    -webkit-transform: translate(-50%, 115%);
    transform: translate(-50%, 115%);
  }
}

.bg-purple {
  background: linear-gradient(180deg, #7a00a5 0%, #bd00ff 99.09%);
}

.wrapper {
  width: 100%;
}

.header,
.banner {
  max-width: 1440px;
  width: 100%;
}

.bg-images {
  max-width: 1400px;
  width: 100%;
  height: 550px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: -1000;
  .bg-img {
    position: absolute;
    z-index: -1000;
    &1 {
      top: -8%;
      left: 91%;
      width: 65px;
    }
    &2 {
      top: 30%;
      left: 88%;
      width: 50px;
    }
    &3 {
      top: 67%;
      left: 70%;
      width: 28px;
    }
    &4 {
      top: 69%;
      left: 54%;
      width: 128px;
    }
    &5 {
      top: 87%;
      left: 48%;
      width: 18px;
    }
    &6 {
      top: 77%;
      left: 1%;
      width: 150px;
    }
    &7 {
      top: -5%;
      left: 3%;
      width: 31px;
    }
  }
}

.header {
  width: 100%;
  padding: 0 120px;

  margin-bottom: 30px;

  &__logo {
    font-size: 30px;
    b {
      font-size: 30px;
    }

    img {
      width: 60px;
      height: 60px;
    }
  }

  .img-c {
    position: relative;
    top: -20px;
  }

  .header__right img {
    width: 30px;
    height: 30px;
  }
}

.switch-form .button-start.mobile {
  display: none;
}

a.button-start,
button.form-button {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.button-start {
  display: inline-block;
  max-width: 270px;
  width: 100%;
  height: 77px;
  border: none;
  border-radius: 100px;
  font-weight: 600;
  font-size: 18px;
  line-height: 37px;

  &:hover {
    transform: scale(1.05, 1.05);
    transition: all 0.3s ease-in-out;
  }

  &:active {
    transform: scale(1, 1);
    opacity: 0.7;
    transition: all 0.4s ease-in-out;
  }
}

.banner {
  display: flex;
  padding: 0 120px;
  width: 100%;
  margin: 0 auto;

  margin-bottom: 100px;

  h1 {
    font-weight: 800;
    font-size: 35px;

    color: #000000;
    margin-bottom: 40px;
  }

  h2 {
    font-weight: 400;
    font-size: 25px;
    /* identical to box height */
    margin-bottom: 40px;

    b {
      font-size: 25px;
    }

    color: #000000;
  }
  div {
    flex: 1;
  }

  .banner-discount {
    width: 400px;
    text-align: center;
  }

  .banner-image {
    text-align: center;
    img {
      max-width: 375px;
      width: 100%;
    }
  }

  .banner-discount p {
    margin-bottom: 20px;
  }

  span.text-purple {
    font-weight: 700;
    text-align: center;

    color: #bf13fc;
  }

  .banner-timer {
    font-weight: 600;
    font-size: 35px;
    text-align: center;

    color: #000000;

    margin-bottom: 20px;
  }

  .button-start {
    background: linear-gradient(180deg, #bf13fc 0%, #6b0090 100%);
    border-radius: 100px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0px 0px 30px #d661ff;

    line-height: 84px;
    height: 85px;

    font-weight: 700;
    font-size: 25px;
    text-align: center;

    color: #ffffff;
  }
}

.banner-under {
  img {
    width: 100%;
  }
  .banner-mobile {
    display: none;
  }

  margin-bottom: 100px;
}

.switch-form {
  padding-bottom: 100px;
  h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    text-align: center;

    color: #000000;
    margin-bottom: 23px;
  }

  .title-desc {
    max-width: 700px;
    width: 100%;
    margin: auto;

    font-size: 21px;
    text-align: center;

    color: #000000;
  }

  .img {
    text-align: center;

    background-image: url("../images/stars.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 90% 90%;
    background-position: center;

    margin-bottom: 50px;

    .gift-mobile {
      display: none;
    }

    img {
      max-width: 540px;
      width: 100%;
    }
  }

  .button-start {
    background: linear-gradient(180deg, #d661ff 0%, #6d0094 100%);
    border-radius: 100px;
    max-width: 400px;
    width: 100%;

    font-weight: 700;
    text-align: center;

    color: #ffffff;

    margin-bottom: 100px;

    line-height: 96px;
    font-size: 25px;
    height: 100px;
  }
}

.course-people {
  padding: 0 130px;

  h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 41px;
    max-width: 600px;
    margin: 0 auto;

    span {
      font-size: 41px;
    }

    margin-bottom: 100px;
    /* or 123% */

    text-align: center;

    color: #000000;
  }

  .button-start {
    background: linear-gradient(180deg, #bf13fc 0%, #6b0090 100%);
    box-shadow: 0px 0px 30px #d661ff;
    border-radius: 100px;

    max-width: 400px;
    width: 100%;
    font-weight: 700;
    text-align: center;

    color: #ffffff;

    margin-bottom: 200px;

    line-height: 75px;
    font-size: 25px;
  }
}

.people-holder {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  gap: 80px;

  padding: 0 150px;

  margin-bottom: 200px;
  .people-item {
    width: 370px;

    // &:nth-child(even) {
    //   margin: 30px 120px 30px 50px;
    // }
    // &:nth-child(odd) {
    //   margin: 30px 50px 30px 120px;
    // }

    .img-holder {
      background: radial-gradient(
          139.8% 145.82% at -10.2% -6.6%,
          #d661ff 0%,
          #7a00a5 56.57%,
          #cc5cf3 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
      box-shadow: inset 0px 12px 4px rgba(0, 0, 0, 0.25);

      width: 300px;
      margin: 0 auto;
      height: 300px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 300px;
      }

      margin-bottom: 30px;
    }

    .item-title {
      font-weight: 900;
      font-size: 25px;
      text-align: center;

      color: #000000;

      margin-bottom: 10px;
    }

    .item-desc {
      text-align: center;
    }
  }
}

.diagonal-box {
  background: #000000;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
  transform: rotate(-4deg) skew(-4deg) scaleX(1.008);

  font-style: italic;
  font-weight: 900;
  font-size: 40px;
  color: #faff00;
  text-align: center;

  padding: 35px;

  margin-bottom: 100px;
}

.info-course {
  .img {
    padding: 30px;
    img {
      max-width: 900px;
      width: 100%;
      margin: 0px;
    }

    margin-bottom: 100px;
  }

  .gift-mobile {
    display: none;
  }

  .button-start {
    border: 3px solid #cb0000;
    border-radius: 100px;

    background-color: transparent;

    max-width: 400px;
    width: 100%;

    font-weight: 700;
    font-size: 28px;
    text-align: center;

    color: #cb0000;

    margin-bottom: 200px;

    line-height: 68px;
  }
}

.result {
  padding: 70px 150px 130px;
  background: linear-gradient(89.61deg, #bf13fc -36.23%, #000000 112.62%);

  .h3-result,
  .h3-result > span {
    text-align: center;

    font-style: italic;
    font-weight: 900;
    font-size: 35px;

    color: #ffffff;

    margin-bottom: 50px;
  }

  .h3-result {
    .desktop-h {
      background-image: url("../images/desktop-h.svg");
      background-repeat: no-repeat;
      background-size: 100% 160%;
      padding-bottom: 20px;
      margin-bottom: 0;
    }
  }

  .grid-holder {
    width: 1300px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 50px;
  }

  .grid-item {
    display: flex;
    align-items: center;

    max-width: 370px;
    width: 100%;

    height: 100px;
    span {
      margin-right: 20px;

      font-weight: 600;
      font-size: 45px;

      color: #d3caff;
    }

    p {
      flex: 1;
      font-size: 15px;
      color: #ffffff;

      b {
        font-size: 15px;
      }
    }

    padding: 20px;

    background: linear-gradient(180deg, #ae88dd 0%, #8750cb 100%);
    border-radius: 10px;
  }

  .button-start {
    margin-top: 70px;
    max-width: 340px;
    width: 100%;
    font-weight: 600;
    font-size: 25px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;

    border: 3px solid #ffffff;
    border-radius: 100px;

    background: transparent;

    line-height: 68px;
  }
}

.program-course {
  width: 100%;
  padding: 0 100px;
  padding-top: 100px;
  padding-bottom: 150px;

  .program-title {
    font-weight: 900;
    font-size: 65px;
    text-align: center;
    font-family: sans-serif;

    color: #000;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;

    margin-bottom: 100px;
  }

  .course-holder {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;

    display: flex;
    justify-content: space-around;
    margin-bottom: 150px;
  }

  .course-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 390px;
    padding: 25px 7px 10px;
    background: radial-gradient(
        95.78% 107.98% at 4.17% 1.86%,
        #7a00a5 0%,
        #a46cff 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    z-index: 0;
    & > * {
      z-index: 2;
    }
  }

  img.zakup-img {
    position: absolute;
    transform: translate(-50%, -80%);
    z-index: 1;

    top: 63%;
    left: 50%;
    width: 70%;
  }

  .sk-img {
    position: absolute;
    top: 57%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -65%);
    z-index: 1;
  }

  .course-title {
    color: #fff;
    margin-bottom: 5px;
    font-weight: 900;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }

  .course-desc {
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
    font-size: 15px;
  }

  .course-steps {
    text-align: center;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    flex: 1;

    padding-left: 10px;
    margin-bottom: 10px;
  }

  .step {
    & > * {
      color: #fff;
    }
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
  }

  .step-first .step-number {
    font-size: 28px;
  }

  .step-number {
    width: 50px;
    padding: 0px 0px;
    line-height: 37px;
    text-align: center;
    font-weight: 700;
    font-size: 35px;
    /* or 156% */

    color: #ffffff;
  }
  .step-desc {
    flex: 1;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    /* or 115% */

    color: #ffffff;
  }

  .n3 {
    padding-left: 8px;
  }

  .button-start {
    line-height: 59px;
    background: #ffffff;
    color: #6a25d7;
    margin-bottom: 35px;
    font-weight: 800;
    font-size: 23px;
    max-width: 294px;
    height: 60px;
  }

  .teacher {
    .teacher-title {
      text-align: center;
      line-height: 79px;
      b {
        font-size: 65px;
      }
      font-size: 65px;
      font-weight: normal;
      color: #000000;
      margin-bottom: 150px;
    }

    .teacher-card {
      border: 6px solid #ecb6ff;
      border-radius: 35px;
      max-width: 600px;
      width: 100%;
      margin: 0 auto;

      background: #fff;
      position: relative;

      padding: 200px 0 0px;

      .teacher-name {
        text-align: center;
        margin-bottom: 30px;

        font-weight: 600;
        font-size: 25px;

        color: #000000;
      }

      .teacher-img {
        position: absolute;
        top: -130px;
        left: 50%;
        transform: translateX(-50%);

        background: radial-gradient(
            139.8% 145.82% at -10.2% -6.6%,
            #d661ff 0%,
            #7a00a5 56.57%,
            #cc5cf3 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        box-shadow: inset 0px 12px 4px rgba(0, 0, 0, 0.25);

        width: 300px;
        height: 300px;
        border-radius: 50%;
        overflow: hidden;

        img {
          position: relative;
          top: 10px;
        }
      }

      ul {
        margin-bottom: 40px;
        padding: 0 70px;
      }

      li {
        position: relative;
        list-style: none;
        padding-left: 18px;
        font-size: 20px;

        color: #000000;
      }

      li:before {
        position: absolute;
        content: "·";
        font-size: 40px;
        line-height: 20px;
        top: 0;
        left: 0;
      }

      .button-start {
        margin-bottom: 60px;
        color: #bf13fc;
        border: 1px solid #bf13fc;
        font-size: 22px;
        height: 60px;
        line-height: 56px;
      }
    }

    margin-bottom: 150px;
  }
  .payment {
    width: 100%;
    max-width: 750px;

    margin: 0 auto;

    .payment-title {
      font-weight: 800;
      font-size: 50px;
      text-align: center;
      color: #000000;
      margin-bottom: 15px;
    }

    .payment-desc {
      font-weight: 400;
      font-size: 30px;
      text-align: center;
      color: #000000;
      margin-bottom: 31px;
    }

    .payment-item {
      border-radius: 24px;
      padding: 30px;
      margin-bottom: 30px;

      max-width: 700px;
      width: 100%;

      margin: 0 auto;
      border: 2px solid #000000;

      display: flex;
      align-items: center;
      img {
        width: 40px;
        margin-right: 40px;
      }

      p {
        font-weight: 600;
        font-size: 25px;
        /* identical to box height */

        color: #000000;
      }
      margin-bottom: 30px;
    }

    .button-start {
      color: #000000;
      border: 2px solid #000000;
      background: transparent;
      margin-bottom: 25px;
      font-weight: 600;
      line-height: 53px;
    }

    .payment-text {
      text-align: center;
      font-weight: 400;
      font-size: 15px;
      color: #000000;
    }
  }
}

.vse-zakupki-eto {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 70px;

  text-align: center;

  .eto-title {
    font-weight: 900;
    font-size: 40px;
    line-height: 49px;
    /* identical to box height */

    background: linear-gradient(180deg, #bf13fc 0%, #620384 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .eto {
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;

    color: #000000;

    margin-bottom: 100px;
  }

  .eto-info-holder {
    margin-bottom: 100px;
  }
  .eto-info-item {
    img {
      margin-bottom: 10px;
    }
    .eto-word {
      margin-bottom: 20px;
    }
  }

  .eto-info-desk {
  }

  .eto-word {
    font-size: 28px;

    font-weight: 900;
    background: linear-gradient(180deg, #acacac 0%, #000000 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .button-start {
    color: #bf13fc;
    font-weight: 700;
    font-size: 20px;

    border: 2px solid #bf13fc;
    filter: drop-shadow(0px 0px 20px #bf13fc);
    border-radius: 100px;
  }
}

.tarifs {
  width: 100%;
  padding: 0 0px;
  padding-top: 50px;
  padding-bottom: 1px;

  .h3-title,
  .h4-title {
    color: #ffffff;
    font-weight: 900;
  }

  .h3-title {
    font-size: 55px;
    margin-bottom: 6rem;
  }

  .h4-title {
    font-size: 25px;
  }

  p {
    color: #ffffff;
    font-size: 15px;
    font-weight: 400;
  }

  .tarif-type-title {
    margin-bottom: 120px;
  }

  .card-holder {
    display: flex;
    align-items: baseline;

    max-width: 1300px;
    margin: 0 auto;

    &.card-holder-1 {
      gap: 50px;
      .card-item {
        padding: 85px 15px 60px;
        ul li {
          text-transform: lowercase;
          b.cap {
            text-transform: uppercase;
          }
        }
        .discount {
          right: -39px;
          font-size: 24px;
          width: 90.29px;
          height: 44.95px;
          line-height: 43px;
        }
      }
    }

    .card-item {
      background: #ffffff;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.45);
      border-radius: 15px;
      width: 365px;
      padding: 0 30px;
      position: relative;
      padding-top: 120px;
      padding-bottom: 60px;

      ul {
        margin-bottom: 40px;
      }

      .img-holder-1,
      .img-holder-2 {
        img {
          width: 100px;
        }
        padding: 10px;
        background: #ffffff;
        position: absolute;
        top: -60px;
        left: 50%;
        transform: translateX(-50%);
      }

      .img-holder-1 {
        border-radius: 50%;
      }

      .img-holder-2 {
        display: flex;
        border-radius: 20px;
        padding: 10px 20px;

        img + img {
          margin-left: 30px;
        }
      }

      .discount {
        position: absolute;
        top: 64%;
        right: -56px;

        text-align: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 51px;
        color: #ff0000;

        width: 122.29px;
        height: 54.95px;

        background: #ebff00;
        border: 1px solid #ffe600;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        transform: rotate(10deg);
      }

      li,
      b,
      span {
        font-size: 15px;
      }

      li {
        position: relative;
        list-style: none;
        padding-left: 18px;

        color: #000000;
      }

      li:before {
        position: absolute;
        content: "·";
        font-size: 33px;
        line-height: 20px;
        top: -3px;
        left: 0;
      }

      li.text-red-line-through {
        text-decoration: line-through;
        color: #ff0000;
      }

      li.text-purple {
        color: #d661ff;
        b {
          color: #8000ad;
        }
      }

      .price-red {
        color: #ff0000;
      }

      .price-big {
        font-weight: 600;
        font-size: 37px;
        line-height: 61px;

        color: #7a00a5;
      }

      .price-desc {
        color: #000000;
      }

      .strikethrough {
        position: relative;
      }
      .strikethrough:before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        right: 0;
        border-top: 1px solid;
        border-color: inherit;

        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
      }

      .button-start {
        font-size: 20px;
        line-height: 55px;
        height: 55px;
        color: #ffffff;
        background: linear-gradient(180deg, #c65aec 0%, #8100ae 100%);
      }
    }
  }

  .card-holder.card-holder-1 {
    justify-content: center;
    margin-bottom: 150px;
  }

  .h5-title {
    color: #000000;
    text-align: center;
    font-weight: 700;
    font-size: 25px;
    line-height: 37px;
    /* identical to box height */

    color: #000000;

    padding-bottom: 10px;

    &.mobile {
      display: none;
    }
  }

  .card-holder.card-holder-2 {
    display: flex;
    margin-bottom: 150px;

    .card-item {
      width: 430px;
      padding-top: 100px;

      padding-top: 80px;
    }

    ul {
      height: 460px;
      margin-bottom: 60px;
    }
    .discount {
      top: 67%;
    }

    .red-diagonal {
      position: relative;
      display: inline-block;

      &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        right: 0;
        border-top: 2px solid;
        border-color: #ff0000;
        -webkit-transform: rotate(-5deg);
        transform: rotate(23deg);
      }
    }
  }

  .card-holder.card-holder-3 {
    display: flex;

    .card-item {
      max-width: 470px;
      width: 100%;
      padding-top: 100px;

      padding-top: 80px;

      ul {
        margin-bottom: 30px;
      }
    }

    .discount {
      top: 71%;
    }
  }
}

.form-registration {
  margin-top: 150px;
  max-width: 1200px;
  width: 100%;
  .form-title {
    font-weight: 900;
    font-size: 50px;
    text-align: center;
    margin-bottom: 5px;
  }

  .p-title {
    font-size: 25px;
  }

  input {
    border-style: none;
    background: transparent;
    outline: none;
  }

  .form-container {
    .form-group {
      max-width: 600px;
      width: 100%;
      margin: auto;
      position: relative;
    }

    .bt-line {
      position: absolute;
      width: 90%;
      border-top: 2px solid #000000;
      top: 55%;
      left: 50%;
      transform: translateX(-50%);
    }

    .input-t {
      border: 2px solid #000000;
      border-radius: 30px;
      height: 100px;
      padding: 30px;
      margin-bottom: 30px;
      position: relative;
    }

    .container {
      display: block;
      position: relative;
      padding-left: 18px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 2px;
      left: 11px;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: #2196f3;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 10px;
      top: 7px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .form-button {
    display: inline-block;
    max-width: 430px;
    width: 100%;
    height: 90px;
    background: linear-gradient(94.67deg, #d661ff 6.97%, #6a008f 101.94%);
    border-radius: 100px;
    border: none;
    font-weight: 700;
    font-size: 25px;
    color: #ffffff;
    line-height: 85px;

    &:hover {
      transform: scale(1.05, 1.05);
      transition: all 0.3s ease-in-out;
    }
  }
}

.footer {
  width: 100%;
  max-width: 1200px;
  margin: auto;

  margin-top: 150px;
  margin-bottom: 150px;

  &-socials {
    margin-bottom: 100px;
  }

  &-address {
    font-size: 15px;
    color: #808080;

    margin-bottom: 100px;
  }

  .logo {
    font-size: 19px;
  }
  &__logo {
    font-size: 15px;
  }
}

@media screen and (max-width: 1500px) {
  .result .grid-item {
    max-width: 400px;
    width: 100%;
  }
}

.tarifs .card-holder.card-holder-1 .card-item {
  padding-top: 85px;
}

.card-holder.card-holder-2 {
  gap: 150px;
}

@media screen and (max-width: 1440px) {
  .card-holder {
    flex-wrap: wrap;
    gap: 150px;
  }

  .tarifs .card-holder.card-holder-2 .card-item .discount {
    top: 68%;
    right: -30px;
    font-size: 20px;
    width: 90.29px;
    height: 50.95px;
  }
  .tarifs .card-holder-1 .card-item .discount {
    right: -20px;
    font-size: 26px;
    line-height: 51px;
    height: 50.95px;
  }
  .card-holder-1 {
    gap: 40px;
    justify-content: center;
  }

  .tarifs .card-holder-1 .card-item {
    width: 355px;

    padding: 85px 15px 60px;
  }
}

@media screen and (max-width: 1400px) {
  .card-holder {
    padding: 0px 100px;
    gap: 150px;
    &.card-holder-1 {
      gap: 30px;
      padding: 0px 50px;
    }
  }

  .people-holder {
    padding: 0 100px;
  }

  .footer {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media screen and (max-width: 1350px) {
  .program-course .course-holder {
    flex-wrap: wrap;
    gap: 70px;
  }
}

@media screen and (max-width: 1280px) {
  .tarifs .card-holder.card-holder-1 .card-item .discount {
    top: 65%;
    right: -34px;
    font-size: 23px;
    width: 81.29px;
    height: 42.95px;
    line-height: 43px;
  }

  .tarifs .card-holder-1 .card-item {
    width: 330px;
  }

  .tarifs .card-holder .card-item li,
  .tarifs .card-holder .card-item b {
    font-size: 14px;
  }

  .tarifs .card-holder .card-item .price-big {
    font-weight: 600;
    font-size: 33px;
  }
  .wrapper {
    background-position-x: 100%, 91%, 79%, 67%, 45%, 0%, 2%;
    background-position-y: 1%, 3%, 3.8%, 3.8%, 4.5%, 4.3%, 0.8%;
  }
  .people-holder {
    padding: 0px;
  }

  .course-people {
    padding: 0 100px;
  }

  .eto-info-holder {
    padding: 0 50px;
    gap: 80px;
    flex-wrap: wrap;
    justify-content: center;

    .eto-info-item {
      width: 365px;
    }
  }
}

@media screen and (max-width: 1276px) {
  .header {
    padding: 70px 120px;
    .img-c {
      display: none;
    }
  }

  .tarifs .card-holder.card-holder-2 ul {
    height: 370px;
  }

  .card-holder {
    flex-wrap: wrap;
  }

  .card-holder.card-holder-2 {
    padding: 0px 50px;
    gap: 50px;

    justify-content: center;
  }

  .header__logo {
    margin-right: 50px;
  }

  .banner {
    gap: 50px;
    h1 {
      font-size: 30px;
    }

    h2,
    h2 > b {
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .tarifs .card-holder.card-holder-1 .card-item {
    width: 300px;
  }
  .tarifs .card-holder.card-holder-1 .card-item {
    .img-holder-2 {
      transform: scale(0.8, 0.8) translate(-170px);
    }

    .img-holder-1 {
      transform: scale(0.8, 0.8) translate(-75px);
    }

    .tarifs .card-holder.card-holder-1 .card-item {
      padding: 82px 15px 40px;
    }
  }
}

@media screen and (max-width: 1170px) {
  .wrapper {
    background-image: unset;
  }

  .tarifs .card-holder .card-item li,
  .tarifs .card-holder .card-item b {
    font-size: 13px;
  }

  .tarifs .card-holder.card-holder-1 .card-item {
    width: 275px;
  }

  .banner {
    margin-bottom: 50px;
  }
}

.owl-next.disappear,
.owl-prev.disappear {
  display: none;
}

.carousel.card-holder {
  display: none;
}

.carousel.card-holder.card-holder-2 {
  display: none;
}

.info-course {
  img.mobile {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .h3-result {
    .desktop-h {
      display: inline-block;
    }
  }

  .form-registration .form-container .form-group {
    transform: scale(0.9, 0.9);
  }
  .form-registration .form-title {
    font-weight: 900;
    font-size: 44px;
  }
  .form-registration .p-title {
    font-size: 22px;
  }

  .form-registration .form-button {
    transform: scale(0.9, 0.9);
  }
  .carousel.card-holder.card-holder-2 {
    .red-diagonal {
      position: relative !important;

      &:before {
        position: absolute !important;
        content: "" !important;
        left: 0 !important;
        top: 50% !important;
        right: 0 !important;
        border-top: 2px solid !important;
        border-color: #ff0000 !important;
        transform: rotate(23deg) !important;
      }
    }
  }

  .carousel.card-holder.card-holder-2 .img-holder-2 {
    transform: scale(0.9) translate(-150px);
  }

  .tarifs .card-holder.card-holder-2 ul {
    height: unset;
  }

  .header {
    margin-top: 30px;
  }

  .tarifs .tarif-type-title {
    margin-bottom: 30px;
  }

  .tarifs .tarif-type-title.tarif-type-title-3 {
    margin-bottom: 100px;
  }

  .tarifs .card-holder.card-holder-1 .card-item {
    width: 350px;
  }

  .tarifs .card-holder .card-item ul {
    margin-bottom: 20px;
  }

  .tarifs .card-holder.card-holder-1 .card-item .img-holder-2 {
    -webkit-transform: scale(0.8, 0.8) translate(-170px);
    transform: scale(0.9, 0.9) translate(-150px);
  }

  .tarifs .card-holder.card-holder-1 .card-item .img-holder-1 {
    -webkit-transform: scale(0.8, 0.8) translate(-75px);
    transform: scale(0.9, 0.9) translate(-62px);
  }

  .tarifs .card-holder .card-item .img-holder-2 {
    transform: scale(0.9, 0.9) translate(-150px);
  }

  .tarifs .card-holder .card-item li,
  .tarifs .card-holder .card-item b {
    font-size: 15px;
    span {
      font-size: 15px;
    }
  }

  .bg-images {
    display: none;
    .bg-img {
      position: absolute;
      z-index: -1000;
      &1 {
        top: 6%;
        left: 93%;
        width: 65px;
      }
      &2 {
        top: 25%;
        left: 86%;
        width: 60px;
      }
      &3 {
        top: 30%;
        left: 66%;
        width: 37px;
      }
      &4 {
        top: 30%;
        left: 50%;
        width: 122px;
      }
      &5 {
        top: 34%;
        left: 43%;
        width: 23px;
      }
      &6 {
        top: 33%;
        left: 1%;
        width: 120px;
      }
      &7 {
        top: 3%;
        left: 2%;
        width: 30px;
      }
    }
  }

  .banner h1 {
    font-size: 25px;
  }

  .tarifs .tarif-type-title.tarif-type-title-1 {
    margin-bottom: 50px;
  }

  .carousel.card-holder {
    display: block;
  }

  .carousel.card-holder.card-holder-2 {
    display: block;
  }

  .card-holder:not(.carousel) {
    display: none;
  }

  .card-holder.card-holder-2:not(.carousel) {
    display: none;
  }

  .carousel .owl-dot {
    width: 10px;
    height: 10px;

    margin: 0 5px;

    border-radius: 50%;
    border: 1px solid #ffffff !important;
  }

  .carousel .owl-dots {
    text-align: center;
  }

  .carousel.card-holder-2 .owl-dots {
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  .carousel .owl-dot.active {
    background-color: #ffffff !important;
  }

  .carousel.card-holder-2 {
    margin-bottom: 100px;
  }

  .carousel.card-holder-1,
  .carousel.card-holder-2 {
    padding: 0px 0px;

    .card-item {
      margin: 0 auto;
      margin-top: 60px;
    }

    gap: 0;

    .owl-next,
    .owl-prev {
      position: absolute;
    }

    .owl-next {
      width: 40px;
      height: 40px;
      border-radius: 50%;

      background: linear-gradient(
        128.09deg,
        #d3caff 3.3%,
        rgba(211, 202, 255, 0) 128.86%
      ) !important;
      backdrop-filter: blur(5px);

      top: 45%;
      left: 85%;

      i {
        position: relative;
        top: -5px;
        left: 0px;
      }
    }

    .owl-prev {
      width: 40px;
      height: 40px;
      border-radius: 50%;

      top: 45%;
      left: 10%;

      background: linear-gradient(
        124.73deg,
        #ff68ff -91.23%,
        rgba(255, 104, 255, 0) 86.93%
      ) !important;
      backdrop-filter: blur(5px);

      i {
        position: relative;
        top: -3px;
        left: 0px;
      }
    }
  }

  .header {
    justify-content: space-between;
    padding: 0 50px;
    margin-bottom: 30px;
  }

  .banner,
  .footer {
    padding-right: 50px;
    padding-left: 50px;
  }

  .course-people {
    padding: 0 50px;
  }

  .banner .banner-timer {
    font-size: 27px;
  }

  .header__right {
    & > a:not(.input-tel) {
      display: none;
    }
    &.w100p {
      width: 230px;
    }
  }

  .header__logo {
    transform: scale(0.7, 0.7) translate(1px);
  }
}

@media screen and (max-width: 1000px) {
  .banner {
    // flex-irection: column;
  }
}

@media screen and (max-width: 920px) {
  .course-people h3 {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 860px) {
  .bg-images {
    display: none;
  }

  .program-course .payment .button-start {
    width: 77%;
  }

  .program-course {
    margin-bottom: 100px;
  }

  .program-course .program-title {
    margin-top: 50px;
    font-size: 37px;
  }
  .banner {
    flex-direction: column-reverse;
    gap: 0;

    h1,
    h2 {
      text-align: center;
    }

    h1 {
      max-width: 490px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 30px;
    }

    h2 {
      font-size: 24px;
      b {
        font-size: 24px;
      }
      margin-bottom: 25px;
    }

    .banner-text {
      text-align: center;
    }

    .banner-discount {
      width: 100%;

      p:first-child {
        margin-bottom: 10px;
      }
    }
  }

  .program-course {
    padding: 20px 50px;

    .program-title {
      margin-bottom: 50px;
    }
  }
}

.navbar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.nav-holder {
  background-color: #fff;
  color: #101010;

  padding: 2rem 0.5rem;
  position: relative;

  .nav-menu {
    margin: 13px auto;
    li {
      text-align: center;
      color: #475569;
      list-style-type: none;
    }

    li + li {
      margin-top: 30px;
    }

    .nav-link {
      text-decoration: none;
    }

    .nav-link:hover {
      color: #482ff7;
    }
  }

  .cross {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 20px;
    left: 90%;
  }
}

.navbar.active {
  display: block;
}

.mobile.active {
  display: none;
}

.h4-title.mobile {
  color: #7801a2;
  margin-bottom: 1rem;
}

.tarif-type-title.mobile {
  display: none;
}

.info-course .button-start.mobile {
  display: none;
}

.form-button.mobile {
  display: none;
}

.tarifs .carousel.card-holder.card-holder-1 {
  margin-bottom: 100px;
}

.button-start.mobile-1 {
  display: none;
}

.result .button-start.mobile {
  display: none;
}

.card-holder.card-holder-3 {
  margin-bottom: 150px;
}

@media screen and (max-width: 768px) {
  .alert-message {
    width: 60vw;
    top: -5%;
    font-size: 15px;
  }

  .thunder {
    display: none;
  }
  .result .h3-result {
    .desktop-h {
      background-image: none;
    }

    .mobile-h {
      display: inline-block;
      background-image: url("../images/mobile-h.svg");
      background-repeat: no-repeat;
      background-size: 100% 150%;
      padding-bottom: 20px;
      margin-bottom: 0;
    }
  }

  .program-course .payment .payment-item {
    border: 2px solid #000000;
    border-radius: 15px;
    padding: 25px;
    margin-bottom: 25px;
  }

  .program-course .payment .payment-item img {
    width: 30px;
    margin-right: 25px;
  }

  .program-course .payment .payment-item p {
    font-size: 20px;
  }

  .program-course .button-start {
    font-weight: 600;
    font-size: 22px;
    text-align: center;

    max-width: 275px;
    height: 62px;
    border-radius: 100px;
    line-height: 55px;
  }

  .program-course .payment .payment-title {
    font-weight: 800;
    font-size: 45px;
  }

  .program-course .payment .payment-desc {
    font-weight: 400;
    font-size: 30px;
  }

  .form-registration .form-container .form-group {
    transform: unset;
  }

  .form-registration .form-cfontainer .input-t {
    padding: 5px 20px;
    height: 75px;
  }

  .form-registration .form-container .input-t {
    padding: 0 30px;
    border-radius: 10px;
    height: 80px;
  }

  .form-registration .form-container .bt-line {
    position: absolute;
    width: 89%;
    border-top: 2px solid #000000;
    top: 54%;
  }

  .form-registration .form-button {
    height: 80px;
    line-height: 79px;
  }

  .tarifs .card-holder.card-holder-2 {
    margin-bottom: 40px;
  }
  .tarifs .card-holder.card-holder-2 .card-item {
    width: 390px;
  }

  .tarifs .card-holder .card-item .discount {
    width: 70.68px !important;
    height: 34.75px !important;

    border: 0.5px solid #ffd64b;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    transform: rotate(20deg);

    font-weight: 900;
    font-size: 20px;
    line-height: 33px;
    text-align: center;

    color: #ff0000;
  }

  .tarifs .card-holder.card-holder-3 .discount {
    top: 79%;
    right: -30px;
  }

  .card-holder.card-holder-3 {
    margin-bottom: 50px;

    .text-center.b {
      display: none;
    }

    .card-item {
      padding-bottom: 25px;
    }
  }

  .program-course .teacher .teacher-card {
    ul {
      margin-bottom: 40px;
      padding: 0 50px;
    }
  }

  .result .grid-item {
    .number {
      font-size: 70px;
    }

    p,
    p > b {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .result .button-start:not(.mobile) {
    display: none;
  }

  .result .button-start.mobile {
    display: inline-block;
  }

  .tarifs .h3-title {
    margin-bottom: 35px;
    font-size: 40px;
  }

  .tarifs .card-holder.card-holder-1 .card-item {
    padding: 85px 15px 20px;
  }

  .tarifs .card-holder .card-item .price-big {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 40px;
  }

  .tarifs .carousel.card-holder.card-holder-1 {
    margin-bottom: 30px;
  }

  .carousel.card-holder-2 {
    margin-bottom: 31px;
  }

  .button-start.mobile-1 {
    display: inline-block !important;

    background: unset;

    margin-bottom: 100px;

    width: 280px;
    height: 64px;
    left: 48px;
    top: 10008px;

    border: 2px solid #ffffff;
    filter: drop-shadow(0px 0px 130px #ffffff);
    border-radius: 50px;

    display: block;
    font-weight: 600;
    font-size: 22px;
    line-height: 56px;

    border-radius: 50px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;

    &:hover {
      transform: scale(1.05, 1.05);
    }
  }

  .button-start.mobile-1.mobile-2 {
    margin-bottom: 160px;
  }

  .form-registration {
    .form-title {
      font-size: 38px;
    }

    .p-title {
      font-size: 21px;
    }
  }
  .form-button:not(.mobile) {
    display: none;
  }

  .form-button.mobile {
    display: inline-block;
  }
  .card-holder-2 .tarif-type-title.mobile.mobile-1 {
    margin-bottom: 0px;
  }

  .info-course .button-start:not(.mobile) {
    display: none;
  }

  .info-course .button-start.mobile {
    display: inline-block;
    margin-bottom: 100px;
  }

  .info-course {
    img:not(.mobile) {
      display: none;
    }

    img.mobile {
      display: inline;
    }
  }

  .info-course .img {
    padding: 0 15px;
    margin-bottom: 50px;
  }

  .diagonal-box {
    margin-bottom: 20px;
  }

  .course-people {
    .button-start {
      max-width: 350px;
      line-height: 65px;
      font-size: 22px;

      height: 70px;

      margin-bottom: 100px;
    }
  }
  .course-people span.mobile {
    background-image: url("../images/mobile-underline.svg");
    background-repeat: no-repeat;
    background-size: 100% 140%;
    padding-bottom: 20px;
  }

  .course-people h3 {
    line-height: 55px;
  }

  .switch-form .button-start.mobile {
    display: inline-block;
    height: 65px;

    line-height: 63px;
    font-size: 21px;
  }

  .switch-form .button-start {
    display: none;
  }

  .people-holder {
    margin-bottom: 70px;
  }

  .card-holder {
    padding: 0px 50px;
    gap: 150px;
  }

  .card-holder.card-holder-1 {
    padding: 0px 50px;
    gap: 0px;
  }

  .card-holder-1 .discount {
    display: none;
  }

  .card-holder-2 .h5-title,
  .card-holder-3 .h5-title {
    font-weight: 900;
    font-size: 30px;
    text-align: center;

    color: #710199;
  }

  .tarifs .h5-title.mobile {
    display: block;
  }

  .card-holder-3 .tarif-type-title.mobile.mobile-1 {
    margin-bottom: 2px;
  }

  .h4-title,
  .tarif-type-title {
    display: none;
  }

  .h4-title.mobile,
  .tarif-type-title.mobile {
    display: block;
  }

  .h4-title.mobile {
    color: #7801a2;
  }

  .tarif-type-title.mobile {
    color: #000000;
    margin-bottom: 23px;
  }

  .desktop {
    display: none;
  }

  .mobile.active {
    display: inline;
    font-size: 27px;
  }

  .tarifs .card-holder:not(.card-holder-3) .card-item {
    padding: 0px 20px;
    padding-top: 80px;
    padding-bottom: 10px;

    ul {
      margin-bottom: 20px;
      height: unset;
    }

    .discount {
      top: 78.5%;
    }

    .text-center.b {
      display: none;
    }
  }

  .tarifs .card-holder.card-holder-2 ul {
    height: unset;
    margin-bottom: 40px;
  }

  .form-registration {
    padding: 0 50px;
  }

  .form-registration .form-container .container {
    padding-left: 50px;
    text-align: left;
  }
  .result {
    padding-left: 100px;
    padding-right: 100px;
  }

  .whatsapp {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header__logo {
    margin-right: 0;
  }

  .header .header__right img {
    width: 25px;
    height: 25px;
  }

  .input-tel {
    font-size: 15px;
    margin-right: 20px;
  }

  .header__right {
    justify-content: right;
  }

  .carousel {
    position: relative;
    &.card-holder {
      padding: 0 0;
    }
    .card-item {
      margin-top: 60px;
      margin-left: 205px;
    }

    .owl-prev {
      // top: 50%;
      // left: 12%;
    }

    .owl-next {
      // top: 50%;
      // left: 85%;
    }
  }
}

@media screen and (max-width: 643px) {
  .course-people h3 {
    font-size: 36px;
    span {
      font-size: 36px;
    }
  }
}

.program-course .teacher .teacher-card .teacher-name {
  font-weight: 700;
}

@media screen and (max-width: 600px) {
  .form-registration .form-container .checkmark {
    top: 13px;
  }

  .carousel.card-holder-1 .owl-prev {
    left: 7.5%;
  }
  .tarifs .card-holder .card-item .discount.discount3 {
    top: 82%;
  }

  .carousel.card-holder-2 .owl-prev {
    left: unset;
    right: 85%;
  }

  .result {
    padding-left: 20px;
    padding-right: 20px;
  }
  .program-course .payment .payment-item {
    width: 95%;
  }

  .diagonal-box {
    font-size: 30px;
  }

  .form-registration {
    padding: 0 20px;

    .form-title {
      font-size: 39px;
    }

    & > .text-center:not(.form-title) {
      font-size: 17px;
    }
  }

  .whatsapp {
    right: 5px;
    bottom: 5px;
  }

  .program-course .teacher .teacher-title {
    text-align: center;
    line-height: 68px;
    font-size: 45px;
    b {
      font-size: 45px;
    }
    margin-bottom: 140px;
  }

  .program-course .payment .payment-title {
    font-size: 35px;
  }

  .program-course .payment .payment-item img {
    width: 25px;
    margin-right: 20px;
  }

  .program-course .payment .payment-item p {
    font-size: 18px;
  }

  .program-course .payment .payment-desc {
    font-weight: 400;
    font-size: 22px;
  }

  .program-course .payment .payment-item {
    border: 2px solid #000000;
    border-radius: 15px;
    padding: 20px;
  }

  .program-course {
    padding: 20px 50px;

    .program-title {
      font-size: 27px;
    }
  }
  .header {
    padding: 0 20px;

    .header__right {
      width: 200px;
    }

    .header__logo {
      margin-right: 20px;
    }
  }

  .program-course .program-title {
    font-size: 40px;
    line-height: 52px;
  }

  .eto-info-holder {
    padding: 0;
  }
}

@media screen and (max-width: 580px) {
  .info-course .button-start {
    transform: scale(0.75);
  }

  .program-course .teacher .teacher-title {
    text-align: center;
    line-height: 68px;
    font-size: 32px;

    b {
      font-size: 32px;
    }

    .tarifs .card-holder .card-item .price-red {
      margin-top: 10px;
    }
    .form-registration > .text-center:not(.form-title) {
      font-size: 15px;
    }
  }

  .tarifs .card-holder.card-holder-3 ul {
    height: unset;
  }
  .card-holder {
    padding: 0 60px;
  }

  .form-registration .form-container .input-t {
    padding: 10px 25px;
    height: 80px;
  }

  .program-course .step-number {
    font-size: 32px;
  }
  .program-course .step-desc {
    font-size: 18px;
  }

  .header__logo {
    font-size: 20px;

    b {
      font-size: 20px;
    }

    img {
      width: 40px;
      height: 40px;
    }
  }

  .switch-form .img {
    background-image: url("../images/stars-mobile.svg");
    margin-bottom: 40px;
  }

  .switch-form h3 {
    font-size: 25px;
    padding: 0px 30px;
  }

  .switch-form .title-desc {
    font-size: 15px;
    padding: 0px 10px;
  }

  .banner-under {
    .banner-mobile {
      display: block;
    }
    .banner-desktop {
      display: none;
    }

    margin-bottom: 20px;
  }

  .header {
    justify-content: space-between;
  }

  .header .header__right {
    width: 400px;
    flex: 1;
  }

  .header .header__right img {
    width: 25px;
    height: 25px;
  }

  .program-course {
    padding: 20px 20px;
  }
  .program-course .teacher .teacher-card ul {
    margin-bottom: 40px;
    padding: 0 20px;
  }

  .course-people h3 {
    font-style: normal;
    font-weight: 900;

    font-size: 25px;

    line-height: 40px;
    span {
      font-size: 25px;
    }
    margin-bottom: 50px;
  }

  .people-holder .people-item .item-desc {
    font-size: 15px;
  }

  .people-holder .people-item .item-title {
    font-size: 20px;
  }

  .people-holder .people-item .img-holder {
    width: 250px;
    height: 250px;

    img {
      width: 100%;
    }
  }

  .result {
    background: linear-gradient(180deg, #000000 0%, #620384 100%);

    h3 {
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 550px) {
  .carousel.card-holder-2 .owl-prev {
    right: 87%;
  }

  .carousel.card-holder-2 .owl-next {
    left: 87%;
  }
}

@media screen and (max-width: 545px) {
  .tarifs .card-holder .card-item .discount {
    right: -30px;
  }

  .card-holder {
    padding: 0px 50px;
  }
}

@media screen and (max-width: 520px) {
  .tarifs .card-holder.card-holder-3 .card-item {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .carousel.card-holder-2 .owl-prev {
    right: 87%;
  }

  .carousel.card-holder-2 .owl-next {
    left: 87%;
  }
}

@media screen and (max-width: 519px) {
  .carousel.card-holder-2 .owl-prev {
    right: 89%;
  }

  .carousel.card-holder-2 .owl-next {
    left: 89%;
  }

  .card-holder {
    padding: 0 40px;
  }
}

@media screen and (max-width: 500px) {
  .tarifs .card-holder.card-holder-2 .card-item {
    width: 410px;
  }

  .course-people h3 {
    line-height: 35px;
    font-size: 25px;
    span.mobile {
      font-size: 25px;
      background-size: 100% 125%;
    }
  }

  .tarifs .card-holder .card-item .discount {
    width: 95.29px;
  }

  .card-holder {
    padding: 0 35px;
  }

  .tarifs .card-holder .card-item .discount {
    right: -24px;
  }

  .banner {
    padding-right: 10px;
    padding-left: 10px;
  }

  .footer {
    padding-right: 20px;
    padding-left: 20px;
  }

  .form-registration .form-title {
    font-size: 30px;
    text-align: left;
  }

  .form-registration {
    .p-title.text-center {
      text-align: left;
    }
  }

  .form-registration .form-container .container {
    font-size: 18px;
  }

  .form-registration .form-container input::placeholder {
    font-size: 18px;
  }
}

@media screen and (max-width: 475px) {
  .tarifs .card-holder.card-holder-2 .card-item {
    width: 400px;
  }

  .carousel.card-holder-2 .owl-next {
    left: 88%;
  }

  .carousel.card-holder-2 .owl-prev {
    right: 88%;
  }

  .carousel.card-holder-1 {
    .owl-next {
      left: 87%;
    }

    .owl-prev {
      left: 4%;
    }
  }
}

@media screen and (max-width: 475px) {
  .program-course .teacher .teacher-card {
    padding: 200px 5px 0px;
  }

  .program-course .teacher .teacher-card .teacher-img {
    position: absolute;
    top: -110px;
    transform: scale(0.9) translate(-168px);
  }

  .program-course .teacher .teacher-title {
    margin-bottom: 110px;
  }

  .program-course .teacher .teacher-card {
    border: 2px solid #ecb6ff;
    border-radius: 15px;
  }

  .carousel.card-holder-1 {
    .owl-next {
      top: 47%;
      left: 90%;
    }

    .owl-prev {
      top: 47%;
      left: 1.5%;
    }
  }
}

@media screen and (max-width: 450px) {
  .tarifs .card-holder.card-holder-2 .card-item {
    width: 380px;
  }
}

@media screen and (max-width: 425px) {
  .result .button-start {
    max-width: 274px;
    font-size: 20px;
    line-height: 58px;
    height: 65px;
  }

  .info-course .button-start.mobile {
    display: inline-block;
    margin-bottom: 70px;
  }

  .eto-info-holder {
    .eto-info-item {
      img {
        margin-bottom: 0px;
      }
      .eto-word {
        margin-bottom: 15px;

        font-size: 30px;
        line-height: 37px;
        /* identical to box height */
      }
    }
  }

  .footer .logo {
    font-size: 15px;
    font-weight: 600;
  }
  .footer {
    padding-right: 35px;
    padding-left: 35px;
    margin-top: 70px;

    .logo {
      img {
        width: 25px;
      }
      b {
        font-size: 15px !important;
      }
    }

    .footer-socials {
      .d-flex:not(:first-child) {
        img {
          width: 20px;
        }
      }

      .d-flex:first-child {
        img {
          width: 34px;
          left: -6px;
        }
      }

      p {
        font-size: 15px !important;
      }
    }
  }
  .program-course .payment .payment-item {
    margin-bottom: 15px;
  }

  .program-course {
    .course-holder {
      gap: 40px;
    }
    .course-item {
      padding: 25px 30px 10px;
    }

    img.zakup-img {
      top: 62%;
      width: 82%;
    }

    .sk-img {
      position: absolute;
      top: 51%;
    }

    .course-desc {
      color: #fff;
      text-align: center;
      margin-bottom: 17px;
      font-size: 12px;
    }

    .course-title {
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 17px;
      line-height: 21px;
    }

    .step-first {
      .step-number {
        font-size: 15px !important;
      }
    }
    .step {
      margin-bottom: 6px;
      .step-number {
        width: 15px;
        font-size: 20px;
      }
      .step-desc {
        line-height: 15px;
      }
    }
  }

  .program-course .button-start {
    font-size: 20px;
    height: 59px;
    line-height: 56px;

    font-weight: 800;
    font-size: 20px;

    color: #7a00a5;
  }

  .form-registration .form-button {
    height: 50px !important;
    line-height: 49px;
    width: 76%;
    font-size: 20px;
  }
  .form-registration .form-container .bt-line {
    top: 50%;
    width: 89%;
  }
  .footer-socials {
    margin-bottom: 60px;
  }
  .footer-address {
    font-size: 12px;
    margin-bottom: 60px;
  }

  .form-registration .form-container .input-t {
    padding: 5px 12px;
    height: 60px;
  }

  .form-registration .form-container input::placeholder {
    font-size: 16px;
    color: #000000;
  }

  .tarifs .card-holder.card-holder-2 .card-item {
    padding: 0px 15px;
    padding-top: 80px;
    padding-bottom: 10px;
  }

  .program-course .program-title {
    font-size: 24.9px;
    line-height: 30px;
  }
  .tarifs .card-holder .card-item .img-holder-2 {
    transform: scale(0.8) translate(-170px);
  }

  .carousel.card-holder.card-holder-2 .img-holder-2 {
    transform: scale(0.8) translate(-170px);
  }

  .tarifs .card-holder.card-holder-1 .card-item .img-holder-2 {
    transform: scale(0.8) translate(-170px);
  }

  .program-course .teacher .teacher-card .button-start {
    font-weight: 600;
    font-size: 20px;
    border: 2px solid #bf13fc;
    border-radius: 50px;
    height: 50px;
    line-height: 44px;
  }

  .program-course .teacher .teacher-card .teacher-name {
    font-weight: 800;
    font-size: 17px;
    line-height: 21px;
  }

  .program-course .teacher .teacher-card ul {
    padding: 0px 10px;

    & li {
      font-size: 15px;
      line-height: 18px;

      color: #000000;
      b {
        font-size: 15px;
      }
    }
  }

  .tarifs .card-holder.card-holder-2 .card-item {
    width: 360px;
  }

  .tarifs .card-holder.card-holder-2 ul {
    height: unset;
    margin-bottom: 20px;
  }

  .carousel.card-holder-1 {
    .owl-next {
      left: 87%;
    }

    .owl-prev {
      left: 3.5%;
    }
  }

  .tarifs .carousel.card-holder.card-holder-1 .card-item {
    width: 330px;
  }
  .tarifs .card-holder .card-item .discount.discount3 {
    top: 85%;
    right: -15px;
  }

  .card-holder {
    padding: 0 20px;
  }

  .form-registration .form-container .container {
    font-size: 12px;
    padding-left: 30px;
    line-height: 17px;

    & .checkmark {
      height: 18px;
      width: 18px;
      top: 4px;
      left: 2px;
      transform: scale(0.9);
      &:after {
        left: 7px;
        top: 3px;
      }
    }

    &:after {
      left: 8px;
      top: 5px;
    }
  }

  .form-registration .form-container .input-t {
    padding: 5px 17px;
    height: 65px;
    border-radius: 10px;
  }

  .tarifs .card-holder .card-item .discount {
    right: -12px;
  }

  .form-registration .form-button {
    height: 70px;
  }

  .form-registration {
    .form-container.mt-50 {
      margin-top: 20px !important;
    }
    .form-title {
      font-size: 26px;
    }
    .p-title.text-center {
      font-size: 13px;
      b {
        font-size: 15px;
      }
    }

    .form-group.mb-50 {
      margin-bottom: 20px !important;
    }
  }

  .whatsapp {
    right: 5px;
    bottom: 18px;
  }

  .result .h3-result {
    font-size: 30px;
    span {
      font-size: 30px;
    }
  }

  .result .grid-holder {
    gap: 10px;
  }
  .program-course .payment .payment-title {
    font-size: 25px;
    margin-bottom: 15px;
  }

  .program-course .payment .payment-desc {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 25px;
  }

  .program-course .payment .text-center {
    margin-top: 40px !important;
  }

  .program-course .payment .payment-text {
    font-size: 11px;
  }

  .program-course .payment .payment-item p {
    font-size: 15px;
  }

  .program-course .payment .payment-item img {
    width: 25px;
    margin-right: 20px;
  }

  .program-course .payment .payment-item {
    border-radius: 15px;

    border: 1px solid #000000;
    padding: 20px;
  }

  .program-course .step-first .step-number {
    font-size: 25px;
  }

  .program-course .step-number {
    line-height: 25px;
  }

  .program-course .step-desc {
    font-size: 10px;
  }

  .program-course .n3 {
    padding-left: 2px;
  }

  .program-course .step-number {
    width: 50px;
    margin-right: 10px;
  }

  .program-course .teacher .teacher-card li {
    font-size: 15px;
    b {
      font-size: 15px;
    }

    &:before {
      position: absolute;
      content: "·";
      font-size: 20px;
      line-height: 14px;
      top: 0;
      left: 0;
    }
  }

  .program-course .payment .button-start {
    border: 1px solid #000000;
  }

  .program-title {
    font-size: 27px;
  }

  .result,
  .banner,
  .program-course {
    padding-left: 15px;
    padding-right: 15px;
  }

  .course-people h3 {
    margin-bottom: 0px;
  }

  .banner h1 {
    font-size: 25px;
  }

  .banner .banner-discount p:first-child {
    font-size: 15px;
    span {
      font-size: 15px;
    }
  }

  .banner h2 {
    font-size: 20px;
    b {
      font-size: 19px;
    }

    margin-bottom: 25px;
  }

  .banner .banner-timer {
    font-size: 20px;
  }

  .input-tel {
    font-size: 12px;
  }

  .header .header__right img {
    width: 15px;
    height: 15px;
  }

  .h3-result {
    font-size: 30px;
    span {
      font-size: 30px;
    }
  }

  .header__logo {
    transform: scale(1, 1);
    font-size: 12px;
    b {
      font-size: 12px;
    }

    img {
      width: 25px;
      height: 25px;
    }
  }

  .banner .button-start,
  .switch-form .button-start {
    max-width: 320px;
  }

  .switch-form .button-start.mobile {
    max-width: 265px;
    height: 53px;
    line-height: 51px;
    font-size: 20px;
  }

  .banner .button-start {
    line-height: 63px;
    height: 65px;
    font-weight: 700;
    font-size: 20px;
  }

  .course-people .button-start {
    max-width: 400px;
  }

  .course-people {
    padding: 0 20px;
  }

  .people-holder {
    margin-bottom: 5px;
  }

  .diagonal-box {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .info-course .img {
    padding: 0 10px;
    margin-bottom: 50px;
  }

  .info-course .button-start {
    max-width: 337px;
    font-size: 23px;
  }

  .course-people .button-start {
    font-size: 23px;
  }

  .people-holder {
    padding: 28px;
  }

  .people-holder {
    gap: 40px;
  }

  result .button-start {
    font-size: 23px;
  }

  .header .header__right img {
    width: 20px;
    height: 20px;
  }

  .header {
    justify-content: space-between;
  }
}

@media screen and (max-width: 425px) {
  .tarifs .card-holder .card-item {
    width: 320px;
  }

  .course-people .button-start {
    transform: scale(0.76);
  }
}

@media screen and (max-width: 405px) {
  .tarifs .card-holder.card-holder-2 .card-item {
    width: 335px;
  }
}

@media screen and (max-width: 385px) {
  .tarifs .card-holder .card-item {
    width: 315px;
  }

  .tarifs .carousel.card-holder-2 .card-item {
    width: 315px;
  }

  .card-holder-3 {
    padding: 0 25px;
  }

  .carousel.card-holder-1 {
    .owl-next {
      left: 87%;
    }

    .owl-prev {
      right: 87%;
    }
  }

  .carousel.card-holder-2 {
    .owl-next {
      left: 87%;
    }

    .owl-prev {
      right: 87%;
    }
  }
}

.carousel {
  display: flex;
  //   background: red;
  //   color: #fff;
  //   margin-bottom: 100px;
  .card-item {
    //     height: 300px;
    //     width: 300px;
    // margin-left: 100px;
    //     background: yellow;
    //     text-align: center;
    //     border: 1px solid greenyellow;
  }
}

@media screen and (max-width: 666px) {
  .form-registration .form-container .checkmark {
    top: 14px;
  }
}
