.w100p {
  width: 100%;
}

.h100p {
  height: 100%;
}

.d-ib {
  display: inline-block;
}

.d-if {
  display: inline-flex;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.text-center {
  text-align: center;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.fw-bold {
  font-weight: bold;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@for $i from 0 through 10 {
  .opacity-#{$i} {
    opacity: calc($i / 10);
  }
}

.pos-r {
  position: relative;
}

.pos-a {
  position: absolute;
}

.cu-p {
  cursor: pointer;
}

mb-100 {
  margin-bottom: 100px;
}