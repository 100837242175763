*, ::before, ::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Montserrat", 'Nunito Sans', Inter, sans-serif;
    font-style: normal;
    font-size: 20px;
}

body {
    background: #fff;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: #000;
}

.mx-150 {
    margin-left: 150px;
    margin-right: 150px;
}