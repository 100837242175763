.pulse {
  position: fixed;
  top: 80%;
  right: 10%;
  z-index: 1000;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.pulse:before,
.pulse:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(71, 221, 0, 0.4);
  border-radius: 50%;
  z-index: -1;
  opacity: 0.7;
}

.pulse:before {
  animation: pulse 2s ease-out infinite;
}

.pulse:after {
  animation: pulse 2s 1s ease-out infinite;
}

@keyframes pulse {
  100% {
    transform: translateY(-5px) scaleX(2.2) scaleY(2);
    opacity: 0;
  }
}

@media screen and (min-width: 768px) {
  .pulse {
    display: none;
  }
}
